import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, from, map, Observable, Subscription, tap } from "rxjs";
import { PlaceholderApiService } from "../../services/api/placeholder-api.service";
import { Placeholder, PlaceholderBase } from "../../models/placeholders/placeholder.model";

@Injectable({
  providedIn: 'root'
})
export class PlaceholderService {

  constructor(
    private store: StoreService, 
    private placeholderApiService: PlaceholderApiService
  ) {}

  get placeholders$(): Observable<Placeholder[]> {
    return this.store.state$.pipe(map(state => Object.values(state.placeholders)));
  }

  load(groupIds:number[]): Observable<Placeholder[]> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.placeholderApiService.getAll(groupIds).pipe(
      tap((placeholders) => {
        const state = { ...this.store.state };
        placeholders.forEach((placeholder) => state.placeholders[placeholder.id] = placeholder);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading placeholders:', error);
        throw error;
      })
    );
  }

  addPlaceholder(placeholder: PlaceholderBase): Observable<Placeholder> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.placeholderApiService.add(placeholder)).pipe(
      tap((newPlaceholder) => {
        const state = { ...this.store.state };
        state.placeholders[newPlaceholder.id] = newPlaceholder;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error adding placeholder:', error);
        throw error;
      })
    );
  }

  updatePlaceholder(placeholder: Placeholder): Observable<void> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.placeholderApiService.update(placeholder)).pipe(
      tap(() => {
        const state = { ...this.store.state };
        state.placeholders[placeholder.id] = placeholder;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error updating placeholder:', error);
        throw error;
      })
    );
  }

  deletePlaceholder(placeholderId: number, placeholderGroupId:number): Observable<void> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.placeholderApiService.delete(placeholderId, placeholderGroupId)).pipe(
      tap(() => {
        const state = { ...this.store.state };
        delete state.placeholders[placeholderId];
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error deleting placeholder:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.placeholders = {};
    this.store.setState(state);
  }
}