import { ProgramTemplateSessionCreateDto, ProgramTemplateSessionDto, ProgramTemplateSessionUpdateDto } from "../../dto/programs/program-template-session.dto"
import { ProgramTemplateSession } from "../../models/programs/program-template-session.model"

export class ProgramTemplateSessionMapper {
  
  public static toModel(dto: ProgramTemplateSessionDto): ProgramTemplateSession {
    return {
      id: dto.id,
      order: dto.order,
      title: dto.title,
      description: dto.description,
      isOptional: dto.is_optional,
      occurrences: dto.occurrences,
      frequencyCode: dto.frequency,
      timeSlotId: dto.time_slot.id,
      sessionTemplateId: dto.session_template_id,
    }
  }

  public static toCreateDto(model: ProgramTemplateSession): ProgramTemplateSessionCreateDto {
    return {
      order: model.order,
      title: model.title,
      description: model.description,
      is_optional: model.isOptional,
      occurrences: model.occurrences,
      frequency: model.frequencyCode,
      time_slot_id: model.timeSlotId,
      session_template_id: model.sessionTemplateId,
    }
  }

  public static toUpdateDto(model: ProgramTemplateSession): ProgramTemplateSessionUpdateDto {
    var dto = this.toCreateDto(model)
    return {
      ...dto,
      id: model.id? model.id : null
    }
  }
}