import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, from, map, Observable, of, tap } from "rxjs";
import { ProgramTemplateApiService } from "../../services/api/program-template-api.service";
import { ProgramTemplate, ProgramTemplateBase, ProgramTemplateDetails } from "../../models/programs/program-template.model";

@Injectable({
  providedIn: 'root'
})
export class ProgramTemplateService {
 
  constructor(
    private store: StoreService, 
    private programTemplateApiService: ProgramTemplateApiService
  ) {}

  get programTemplates$(): Observable<ProgramTemplate[]> {
    return this.store.state$.pipe(map(state => Object.values(state.programTemplates)));
  }

  load(): Observable<ProgramTemplate[]> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.programTemplateApiService.getAll().pipe(
      tap((templates) => {
        const state = { ...this.store.state };
        templates.forEach((t) => state.programTemplates[t.id] = t);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading program templates:', error);
        throw error;
      })
    );
  }

  loadDetails(id: number): Observable<ProgramTemplateDetails> {
    const existingProgram = this.store.state.programTemplates[id];
    if ( existingProgram && 'isDetailed' in existingProgram) {
      return of(existingProgram as ProgramTemplateDetails);
    }

    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.programTemplateApiService.get(id)).pipe(
      tap((program) => {
        const state = { ...this.store.state };
        state.programTemplates[program.id] = program;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading program details:', error);
        throw error;
      })
    );
  }

  addProgramTemplate(programTemplate: ProgramTemplateBase, localeId:number): Observable<ProgramTemplateDetails> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.programTemplateApiService.add(programTemplate, localeId)).pipe(
      tap((newTemplate) => {
        const state = { ...this.store.state };
        state.programTemplates[newTemplate.id] = newTemplate;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error adding program template:', error);
        throw error;
      })
    );
  }

  updateProgramTemplate(programTemplate: ProgramTemplateDetails): Observable<ProgramTemplateDetails> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.programTemplateApiService.update(programTemplate)).pipe(
      tap((sessionTemplate) => {
        const state = { ...this.store.state };
        state.programTemplates[sessionTemplate.id] = sessionTemplate;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error updating program template:', error);
        throw error;
      })
    );
  }

  deleteProgramTemplate(id: number): Observable<void> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.programTemplateApiService.delete(id)).pipe(
      tap(() => {
        const state = { ...this.store.state };
        delete state.programTemplates[id];
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error deleting program template:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.programTemplates = {};
    this.store.setState(state);
  }
}