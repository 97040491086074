import { PlaceholderCreateDto } from "../../dto/placeholders/placeholder-create.dto";
import { PlaceholderDto } from "../../dto/placeholders/placeholder.dto";
import { Placeholder, PlaceholderBase } from "../../models/placeholders/placeholder.model";

export class PlaceholderMapper {
  public static toModel(dto: PlaceholderDto, groupId:number): Placeholder {
    return {
      id: dto.id,
      code: dto.code,
      description: dto.description,
      userQuestion: dto.user_question,
      groupId: groupId
    }
  }

  public static toCreateDto(model: PlaceholderBase): PlaceholderCreateDto {
    return {
      code: model.code,
      description: model.description,
      user_question: model.userQuestion
    }
  }
}