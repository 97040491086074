import { HttpClient } from "@angular/common/http";
import { BaseApiService } from "../base-api.service";
import { Injectable } from "@angular/core";
import { environment } from "@beyou/environments/environment";
import { SeriesTemplate, SeriesTemplateBase, SeriesTemplateDetails, SeriesTemplateTranslation } from "../../models/series/series-template.model";
import { firstValueFrom, map, Observable } from "rxjs";
import { SeriesTemplateDto } from "../../dto/series/series-template.dto";
import { SeriesTemplateMapper } from "../../mappers/series/series-template.mapper";
import { SeriesTemplateDetailsDto } from "../../dto/series/series-template-details.dto";
import { SeriesTemplateTranslationsDto } from "../../dto/series/series-template-translations.dto";
import { SeriesTemplateTranslationMapper } from "../../mappers/series/series-template-translation.mapper";

@Injectable({
  providedIn: 'root'
})
export class SeriesTemplateApiService extends BaseApiService {

  constructor(http: HttpClient) { 
    super(http);
  }

  public getAll(): Observable<SeriesTemplate[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/series-templates`;
    return this.getAllPagedData<SeriesTemplateDto>(apiUrl).pipe(
      map((response) => {
        return response.map(item => {
          return SeriesTemplateMapper.toModel(item);
        })
      })
    );
  }

  public async get(id:number): Promise<SeriesTemplateDetails> {
    const apiUrl = `${environment.adminApiBaseUrl}/series-templates/${id}`;
    return await firstValueFrom(this.http.get<SeriesTemplateDetailsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Series details retrieved: ', response.id);
        return SeriesTemplateMapper.toDetailedModel(response);
      })
    ));
  }

  public async update(seriesTemplate: SeriesTemplateDetails): Promise<SeriesTemplateDetails> {
    const apiUrl = `${environment.adminApiBaseUrl}/series-templates/${seriesTemplate.id}`;
    const dto = SeriesTemplateMapper.toUpdateDto(seriesTemplate);
    return await firstValueFrom(this.http.put<SeriesTemplateDetailsDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('series template updated: ', response.id);
        return SeriesTemplateMapper.toDetailedModel(response);
      })
    ));
  }

  public async add(seriesTemplate: SeriesTemplateBase, localeId: number): Promise<SeriesTemplateDetails> {
    const apiUrl = `${environment.adminApiBaseUrl}/series-templates`;
    const dto = SeriesTemplateMapper.toCreateDto(seriesTemplate, localeId);
    return await firstValueFrom(this.http.post<SeriesTemplateDetailsDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('series template added: ', response.id);
        return SeriesTemplateMapper.toDetailedModel(response);
      })
    ));
  }

  public async delete(id: number): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/series-templates/${id}`;
    await firstValueFrom(this.http.delete(apiUrl));
  }

  public async getTranslations(id: number): Promise<SeriesTemplateTranslation[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/series-templates/${id}/translations`;
    return await firstValueFrom(this.http.get<SeriesTemplateTranslationsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Series template translations retrieved: ', id);
        return response.translations.map( item => SeriesTemplateTranslationMapper.toModel(item));
      })
    ));
  }

  public async updateTranslations(id: number, translations: SeriesTemplateTranslation[]): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/series-templates/${id}/translations`;
    const dto = {
      translations: translations.map(item => SeriesTemplateTranslationMapper.toDto(item))
    };
    await firstValueFrom(this.http.post(apiUrl, dto));
    console.debug('Series template translations updated: ', id);
  }
}