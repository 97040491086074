import { BuildingBlockTagTranslationDto } from "../../dto/building-blocks/building-block-tag-translations.dto";
import { BuildingBlockTagTranslation } from "../../models/building-blocks/building-block-tag.model";

export class BuildingBlockTagTranslationMapper {
  public static toModel(dto: BuildingBlockTagTranslationDto): BuildingBlockTagTranslation {
    return {
      localeId: dto.locale_id,
      name: dto.name
    }
  }

  public static toDto(dto: BuildingBlockTagTranslation): BuildingBlockTagTranslationDto {
    return {
      locale_id: dto.localeId,
      name: dto.name
    }
  }
}