import { Injectable } from '@angular/core';
import { BuildingBlockTagMapper } from '../../mappers/building-blocks/building-block-tag.mapper';
import { environment } from '@beyou/environments/environment';
import { BuildingBlockTag, BuildingBlockTagBase, BuildingBlockTagTranslation } from '../../models/building-blocks/building-block-tag.model';
import { BuildingBlockTagDto } from '../../dto/building-blocks/building-block-tag.dto';
import { firstValueFrom, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from '../base-api.service';
import { BuildingBlockTagTranslationsDto } from '../../dto/building-blocks/building-block-tag-translations.dto';
import { BuildingBlockTagTranslationMapper } from '../../mappers/building-blocks/building-block-tag-translation.mapper';

@Injectable({
  providedIn: 'root'
})
export class BuildingBlockTagApiService extends BaseApiService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(): Observable<BuildingBlockTag[]> {
    console.debug('Getting all building block tags');
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-tags`;
    return this.getAllPagedData<BuildingBlockTagDto>(apiUrl).pipe(
      map((response) => {
        return response.map(item => {
          return BuildingBlockTagMapper.toModel(item);
        })
      })
    );
  }

  public async add(buildingBlockTag: BuildingBlockTagBase): Promise<BuildingBlockTag> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-tags`;
    const dto = BuildingBlockTagMapper.toCreateDto(buildingBlockTag);
    return await firstValueFrom(this.http.post<BuildingBlockTagDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('Building block tag added: ', response.id);
        return BuildingBlockTagMapper.toModel(response);
      })
    ));
  }

  public async delete(id: number): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-tags/${id}`;
    console.debug('Building block tag deleted: ', id);
    await firstValueFrom(this.http.delete(apiUrl));
  }

  public async getTranslations(id: number): Promise<BuildingBlockTagTranslation[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-tags/${id}/translations`;
    return await firstValueFrom(this.http.get<BuildingBlockTagTranslationsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Tag translations retrieved: ', id);
        return response.translations.map( item => BuildingBlockTagTranslationMapper.toModel(item));
      })
    ));
  }

  public async updateTranslations(id: number, translations: BuildingBlockTagTranslation[]): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/buildingblock-tags/${id}/translations`;
    const dto = {
      translations: translations.map(item => BuildingBlockTagTranslationMapper.toDto(item))
    };
    await firstValueFrom(this.http.post(apiUrl, dto));
    console.debug('Tag translations updated: ', id);
  }
}
