import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { HttpClient } from '@angular/common/http';
import { Placeholder, PlaceholderBase, PlaceholderTranslation } from '../../models/placeholders/placeholder.model';
import { environment } from '@beyou/environments/environment';
import { PlaceholderMapper } from '../../mappers/placeholders/placeholder.mapper';
import { PlaceholderDto } from '../../dto/placeholders/placeholder.dto';
import { concatAll, firstValueFrom, forkJoin, from, map, mergeMap, Observable, take, tap, toArray } from 'rxjs';
import { PlaceholderTranslationsDto } from '../../dto/placeholders/placeholder-translations.dto';
import { PlaceholderTranslationMapper } from '../../mappers/placeholders/placeholder-translation.mapper';

@Injectable({
  providedIn: 'root'
})
export class PlaceholderApiService extends BaseApiService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(groups: number[]): Observable<Placeholder[]> {
    return from(groups).pipe(
      mergeMap(id => this.getAllPlaceholdersByGroup(id)),
      mergeMap(placeholdersArray => from(placeholdersArray)),
      toArray(),
      tap((placeholders) => console.debug('Placeholders fetched: ', placeholders))
    );
  }

  private getAllPlaceholdersByGroup(groupId: number): Observable<Placeholder[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups/${groupId}/placeholders`;
    return this.getAllPagedData<PlaceholderDto>(apiUrl).pipe(
      map((response) => {
        return response.map(item => {
          return PlaceholderMapper.toModel(item, groupId);
        })
      })
    );
  }

  public async update(placeholder: Placeholder): Promise<void> {
    console.error('NOT IMPLEMENTED: Placeholder updated: ', placeholder.id);
  }

  public async add(placeholder: PlaceholderBase): Promise<Placeholder> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups/${placeholder.groupId}/placeholders`;
    const dto = PlaceholderMapper.toCreateDto(placeholder);
    return await firstValueFrom(this.http.post<PlaceholderDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('Placeholder added: ', response.id);
        return PlaceholderMapper.toModel(response, placeholder.groupId);
      })
    ));
  }

  public async delete(id: number, groupId: number): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups/${groupId}/placeholders/${id}`;
    await firstValueFrom(this.http.delete(apiUrl));
  }

  public async getTranslations(placeholder: Placeholder): Promise<PlaceholderTranslation[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups/${placeholder.groupId}/placeholders/${placeholder.id}/translations`;
    return await firstValueFrom(this.http.get<PlaceholderTranslationsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Placeholder translations retrieved: ', placeholder.id);
        return response.translations.map( item => PlaceholderTranslationMapper.toModel(item));
      })
    ));
  }

  public async updateTranslations(placeholder: Placeholder, translations: PlaceholderTranslation[]): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/placeholder-groups/${placeholder.groupId}/placeholders/${placeholder.id}/translations`;
    const dto = {
      translations: translations.map(item => PlaceholderTranslationMapper.toDto(item))
    };
    await firstValueFrom(this.http.post(apiUrl, dto));
    console.debug('Placeholder translations updated: ', placeholder.id);
  }
}
