import { HttpClient } from "@angular/common/http";
import { BaseApiService } from "../base-api.service";
import { Injectable } from "@angular/core";
import { environment } from "@beyou/environments/environment";
import { firstValueFrom, map, Observable } from "rxjs";
import { ProgramTemplate, ProgramTemplateBase, ProgramTemplateDetails, ProgramTemplateTranslation } from "../../models/programs/program-template.model";
import { ProgramTemplateDto } from "../../dto/programs/program-template.dto";
import { ProgramTemplateDetailsDto } from "../../dto/programs/program-template-details.dto";
import { ProgramTemplateMapper } from "../../mappers/programs/program-template.mapper";
import { ProgramTemplateTranslationsDto } from "../../dto/programs/program-template-translations.dto";
import { ProgramTemplateTranslationMapper } from "../../mappers/programs/program-template-translation.mapper";
import { ProgramTemplateSessionTranslationsDto } from "../../dto/programs/program-template-sessions-translations.dto";
import { ProgramTemplateSessionTranslation } from "../../models/programs/program-template-session.model";
import { ProgramTemplateSessionTranslationMapper } from "../../mappers/programs/program-template-session-translation.mapper";

@Injectable({
  providedIn: 'root'
})
export class ProgramTemplateApiService extends BaseApiService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(): Observable<ProgramTemplate[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/program-templates`;
    return this.getAllPagedData<ProgramTemplateDto>(apiUrl).pipe(
      map((response) => {
        return response.map(item => {
          return ProgramTemplateMapper.toModel(item);
        })
      })
    );
  }

  public async get(id: number): Promise<ProgramTemplateDetails> {
    const apiUrl = `${environment.adminApiBaseUrl}/program-templates/${id}`;
    return await firstValueFrom(this.http.get<ProgramTemplateDetailsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Program details retrieved: ', response.id);
        return ProgramTemplateMapper.toDetailedModel(response);
      })
    ));
  }

  public async update(programTemplate: ProgramTemplateDetails): Promise<ProgramTemplateDetails> {
    const apiUrl = `${environment.adminApiBaseUrl}/program-templates/${programTemplate.id}`;
    const dto = ProgramTemplateMapper.toUpdateDto(programTemplate);
    return await firstValueFrom(this.http.put<ProgramTemplateDetailsDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('Program template updated: ', response.id);
        return ProgramTemplateMapper.toDetailedModel(response);
      })
    ));
  }

  public async add(programTemplate: ProgramTemplateBase, localeId: number): Promise<ProgramTemplateDetails> {
    const apiUrl = `${environment.adminApiBaseUrl}/program-templates`;
    const dto = ProgramTemplateMapper.toCreateDto(programTemplate, localeId);
    return await firstValueFrom(this.http.post<ProgramTemplateDetailsDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('Program template added: ', response.id);
        return ProgramTemplateMapper.toDetailedModel(response);
      })
    ));
  }

  public async delete(id: number): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/program-templates/${id}`;
    await firstValueFrom(this.http.delete(apiUrl));
  }

  public async getTranslations(id: number): Promise<ProgramTemplateTranslation[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/program-templates/${id}/translations`;
    return await firstValueFrom(this.http.get<ProgramTemplateTranslationsDto>(apiUrl).pipe(
      map((response) => {
        console.debug('Program template translations retrieved: ', id);
        return response.translations.map(item => ProgramTemplateTranslationMapper.toModel(item));
      })
    ));
  }

  public async updateTranslations(id: number, translations: ProgramTemplateTranslation[]): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/program-templates/${id}/translations`;
    const dto = {
      translations: translations.map(item => ProgramTemplateTranslationMapper.toDto(item))
    };
    await firstValueFrom(this.http.post(apiUrl, dto));
    console.debug('Program template translations updated: ', id);
  }

  public async getSessionTranslations(id: number): Promise<ProgramTemplateSessionTranslationsItem[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/program-templates/${id}/sessions/translations`;
    return await firstValueFrom(this.http.get<ProgramTemplateSessionTranslationsDto[]>(apiUrl).pipe(
      map((response) => {
        console.debug('Program template sessions translations retrieved: ', id);
        return response.map(item => {
          return {
            id: item.id,
            translations: item.translations.map(item => ProgramTemplateSessionTranslationMapper.toModel(item))
          };
        });
      })
    ));
  }

  public async updateSessionTranslations(id: number, translations: ProgramTemplateSessionTranslationsItem[]): Promise<void> {
    const apiUrl = `${environment.adminApiBaseUrl}/program-templates/${id}/sessions/translations`;
    const dto: ProgramTemplateSessionTranslationsDto[] = [];
    translations.forEach(item => {
      dto.push({
        id: item.id,
        translations: item.translations.map(item => ProgramTemplateSessionTranslationMapper.toDto(item))
      });
    });
    await firstValueFrom(this.http.post(apiUrl, dto));
    console.debug('Program template translations updated: ', id);
  }
}

export type ProgramTemplateSessionTranslationsItem = {
  translations: ProgramTemplateSessionTranslation[];
  id: number;
}