import { SeriesTemplateCreateDto } from "../../dto/series/series-template-create.dto";
import { SeriesTemplateDetailsDto } from "../../dto/series/series-template-details.dto";
import { SeriesTemplateUpdateDto } from "../../dto/series/series-template-update.dto";
import { SeriesTemplateDto } from "../../dto/series/series-template.dto";
import { SeriesTemplate, SeriesTemplateBase, SeriesTemplateDetails } from "../../models/series/series-template.model";
import { CoverMapper } from "../media/cover.mapper";
import { SeriesTemplateSessionMapper } from "./series-template-session.mapper";

export class SeriesTemplateMapper {
  public static toModel(dto: SeriesTemplateDto): SeriesTemplate {
    return {
      id: dto.id,
      title: dto.title,
      originalLocaleId: dto.original_locale_id,
      description: dto.description,
      isActive: dto.is_active,
      therapeuticDomainCode: dto.therapeutic_domain.code,
      cover: dto.cover? CoverMapper.toModel(dto.cover) : null,
      updatedAt: new Date(dto.updated_at),
      createdAt: new Date(dto.created_at),
      sessions: []
    }
  }

  public static toDetailedModel(dto: SeriesTemplateDetailsDto): SeriesTemplateDetails {
    return {
      ...this.toModel(dto),
      isDetailed: true,
      sessions: dto.sessions.map(b => SeriesTemplateSessionMapper.toModel(b)).sort((a, b) => a.order - b.order),
    }
  }

  public static toCreateDto(model: SeriesTemplateBase, localeId: number): SeriesTemplateCreateDto {
    return {
      title: model.title,
      description: model.description,
      locale_id: localeId,
      therapeutic_domain: model.therapeuticDomainCode,
      cover: model.cover? CoverMapper.toDto(model.cover) : undefined,
      sessions: model.sessions.map(b => SeriesTemplateSessionMapper.toCreateDto(b)),
    }
  }

  public static toUpdateDto(model: SeriesTemplate): SeriesTemplateUpdateDto {
    return {
      cover: model.cover? CoverMapper.toDto(model.cover) : undefined,
      sessions: model.sessions.map(b => SeriesTemplateSessionMapper.toUpdateDto(b)),
    }
  }

}