import { SeriesTemplateSessionBaseDto, SeriesTemplateSessionDto } from "../../dto/series/series-template-session.dto";
import { SeriesTemplateSession } from "../../models/series/series-template-session.model";

export class SeriesTemplateSessionMapper {
  
  public static toModel(dto: SeriesTemplateSessionDto): SeriesTemplateSession {
    return {
      id: dto.id,
      order: dto.order,
      sessionTemplateId: dto.session_template_id,
    }
  }

  public static toCreateDto(model: SeriesTemplateSession): SeriesTemplateSessionBaseDto {
    return {
      order: model.order,
      session_template_id: model.sessionTemplateId,
    }
  }

  public static toUpdateDto(model: SeriesTemplateSession): SeriesTemplateSessionDto {
    return {
      id: model.id? model.id : null,
      order: model.order,
      session_template_id: model.sessionTemplateId,
    }
  }
}