
import { ProgramTemplateCreateDto } from "../../dto/programs/program-template-create.dto";
import { ProgramTemplateDetailsDto } from "../../dto/programs/program-template-details.dto";
import { ProgramTemplateUpdateDto } from "../../dto/programs/program-template-update.dto";
import { ProgramTemplateDto } from "../../dto/programs/program-template.dto";
import { ProgramTemplate, ProgramTemplateBase, ProgramTemplateDetails } from "../../models/programs/program-template.model";
import { CoverMapper } from "../media/cover.mapper";
import { ProgramTemplateSessionMapper } from "./program-template-session.mapper";

export class ProgramTemplateMapper {
  public static toModel(dto: ProgramTemplateDto): ProgramTemplate {
    return {
      id: dto.id,
      title: dto.title,
      originalLocaleId: dto.original_locale_id,
      description: dto.description,
      isActive: dto.is_active,
      totalDays: dto.total_days,
      intensityLevelCode: dto.intensity_level,
      cover: dto.cover? CoverMapper.toModel(dto.cover) : null,
      updatedAt: new Date(dto.updated_at),
      createdAt: new Date(dto.created_at),
      sessions: []
    }
  }

  public static toDetailedModel(dto: ProgramTemplateDetailsDto): ProgramTemplateDetails {
    return {
      ...this.toModel(dto),
      isDetailed: true,
      sessions: dto.sessions.map(b => ProgramTemplateSessionMapper.toModel(b)),
    }
  }

  public static toCreateDto(model: ProgramTemplateBase, localeId: number): ProgramTemplateCreateDto {
    return {
      title: model.title,
      description: model.description,
      locale_id: localeId,
      total_days: model.totalDays,
      intensity_level: model.intensityLevelCode,
      cover: model.cover? CoverMapper.toDto(model.cover) : undefined,
      sessions: model.sessions.map(b => ProgramTemplateSessionMapper.toCreateDto(b)),
    }
  }

  public static toUpdateDto(model: ProgramTemplate): ProgramTemplateUpdateDto {
    return {
      cover: model.cover? CoverMapper.toDto(model.cover) : undefined,
      total_days: model.totalDays,
      intensity_level: model.intensityLevelCode,
      sessions: model.sessions.map(b => ProgramTemplateSessionMapper.toUpdateDto(b)),
    }
  }

}