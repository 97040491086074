import { ProgramTemplateSessionTranslation } from "../../models/programs/program-template-session.model"
import { ProgramTemplateSessionTranslationDto } from "../../dto/programs/program-template-sessions-translations.dto";

export class ProgramTemplateSessionTranslationMapper {
  public static toModel(dto: ProgramTemplateSessionTranslationDto): ProgramTemplateSessionTranslation {
    return {
      localeId: dto.locale_id,
      title: dto.title,
      description: dto.description
    }
  }

  public static toDto(dto: ProgramTemplateSessionTranslation): ProgramTemplateSessionTranslationDto {
    return {
      locale_id: dto.localeId,
      title: dto.title,
      description: dto.description
    }
  }
}