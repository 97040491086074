import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, from, map, Observable, tap } from "rxjs";
import { BuildingBlockTag, BuildingBlockTagBase } from "../../models/building-blocks/building-block-tag.model";
import { BuildingBlockTagApiService } from "../../services/api/building-block-tag-api.service";

@Injectable({
  providedIn: 'root'
})
export class BuildingBlockTagService {
 
  constructor(
    private store: StoreService, 
    private buildingBlockTagApiService: BuildingBlockTagApiService
  ) {}

  get buildingBlockTags$(): Observable<BuildingBlockTag[]> {
    return this.store.state$.pipe(map(state => Object.values(state.buildingBlockTags)));
  }

  load(): Observable<BuildingBlockTag[]> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.buildingBlockTagApiService.getAll().pipe(
      tap((tags) => {
        const state = { ...this.store.state };
        tags.forEach((t) => state.buildingBlockTags[t.id] = t);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading building block tags:', error);
        throw error;
      })
    );
  }

  addBuildingBlockTag(tag: BuildingBlockTagBase): Observable<BuildingBlockTag> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.buildingBlockTagApiService.add(tag)).pipe(
      tap((newTag) => {
        const state = { ...this.store.state };
        state.buildingBlockTags[newTag.id] = newTag;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error adding tag:', error);
        throw error;
      })
    );
  }

  deleteBuildingBlockTag(tagId: number): Observable<void> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.buildingBlockTagApiService.delete(tagId)).pipe(
      tap(() => {
        const state = { ...this.store.state };
        delete state.buildingBlockTags[tagId];
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error deleting tag:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.buildingBlockTags = {};
    this.store.setState(state);
  }
}