import { PlaceholderTranslationDto } from "../../dto/placeholders/placeholder-translations.dto";
import { PlaceholderTranslation } from "../../models/placeholders/placeholder.model";

export class PlaceholderTranslationMapper {
  public static toModel(dto: PlaceholderTranslationDto): PlaceholderTranslation {
    return {
      localeId: dto.locale_id,
      description: dto.description,
      userQuestion: dto.user_question
    }
  }

  public static toDto(model: PlaceholderTranslation): PlaceholderTranslationDto {
    return {
      locale_id: model.localeId,
      description: model.description,
      user_question: model.userQuestion
    }
  }
}