import { ProgramTemplateTranslationDto } from "../../dto/programs/program-template-translations.dto"
import { ProgramTemplateTranslation } from "../../models/programs/program-template.model"

export class ProgramTemplateTranslationMapper {
  public static toModel(dto: ProgramTemplateTranslationDto): ProgramTemplateTranslation {
    return {
      localeId: dto.locale_id,
      title: dto.title,
      description: dto.description
    }
  }

  public static toDto(dto: ProgramTemplateTranslation): ProgramTemplateTranslationDto {
    return {
      locale_id: dto.localeId,
      title: dto.title,
      description: dto.description
    }
  }
}