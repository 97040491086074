import { BuildingBlockTagDto } from "../../dto/building-blocks/building-block-tag.dto";
import { BuildingBlockTag, BuildingBlockTagBase } from "../../models/building-blocks/building-block-tag.model";

export class BuildingBlockTagMapper {
  public static toModel(dto: BuildingBlockTagDto): BuildingBlockTag {
    return {
      id: dto.id,
      name: dto.name,
      code: dto.code,
    }
  }

  public static toCreateDto(dto: BuildingBlockTagBase): BuildingBlockTagDto {
    return {
      id: 0,
      name: dto.name,
      code: dto.code,
    }
  }
}