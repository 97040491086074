import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, from, map, Observable, of, tap } from "rxjs";
import { SeriesTemplate, SeriesTemplateBase, SeriesTemplateDetails } from "../../models/series/series-template.model";
import { SeriesTemplateApiService } from "../../services/api/series-template-api.service";

@Injectable({
  providedIn: 'root'
})
export class SeriesTemplateService {
 
  constructor(
    private store: StoreService, 
    private seriesTemplateApiService: SeriesTemplateApiService
  ) {}

  get seriesTemplates$(): Observable<SeriesTemplate[]> {
    return this.store.state$.pipe(map(state => Object.values(state.seriesTemplates)));
  }

  load(): Observable<SeriesTemplate[]> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return this.seriesTemplateApiService.getAll().pipe(
      tap((templates) => {
        const state = { ...this.store.state };
        templates.forEach((t) => state.seriesTemplates[t.id] = t);
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading series templates:', error);
        throw error;
      })
    );
  }

  loadDetails(id: number): Observable<SeriesTemplateDetails> {
    const existingSeries = this.store.state.seriesTemplates[id];
    if ( existingSeries && 'isDetailed' in existingSeries) {
      return of(existingSeries as SeriesTemplateDetails);
    }

    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.seriesTemplateApiService.get(id)).pipe(
      tap((series) => {
        const state = { ...this.store.state };
        state.seriesTemplates[series.id] = series;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading series details:', error);
        throw error;
      })
    );
  }

  addSeriesTemplate(seriesTemplate: SeriesTemplateBase, localeId:number): Observable<SeriesTemplateDetails> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.seriesTemplateApiService.add(seriesTemplate, localeId)).pipe(
      tap((newTemplate) => {
        const state = { ...this.store.state };
        state.seriesTemplates[newTemplate.id] = newTemplate;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error adding series template:', error);
        throw error;
      })
    );
  }

  updateSeriesTemplate(seriesTemplate: SeriesTemplateDetails): Observable<SeriesTemplateDetails> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.seriesTemplateApiService.update(seriesTemplate)).pipe(
      tap((sessionTemplate) => {
        const state = { ...this.store.state };
        state.seriesTemplates[sessionTemplate.id] = sessionTemplate;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error updating series template:', error);
        throw error;
      })
    );
  }

  deleteSeriesTemplate(id: number): Observable<void> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.seriesTemplateApiService.delete(id)).pipe(
      tap(() => {
        const state = { ...this.store.state };
        delete state.seriesTemplates[id];
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error deleting series template:', error);
        throw error;
      })
    );
  }

  clear(): void {
    const state = { ...this.store.state };
    state.seriesTemplates = {};
    this.store.setState(state);
  }
}