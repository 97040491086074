import { SeriesTemplateTranslationDto } from "../../dto/series/series-template-translations.dto";
import { SeriesTemplateTranslation } from "../../models/series/series-template.model";

export class SeriesTemplateTranslationMapper {
  public static toModel(dto: SeriesTemplateTranslationDto): SeriesTemplateTranslation {
    return {
      localeId: dto.locale_id,
      title: dto.title,
      description: dto.description
    }
  }

  public static toDto(dto: SeriesTemplateTranslation): SeriesTemplateTranslationDto {
    return {
      locale_id: dto.localeId,
      title: dto.title,
      description: dto.description
    }
  }
}